'use strict';
const customCarousel = require('../customCarousel');

const handleClickTile = (e) => {
    const item = e.currentTarget;
    const index = parseInt(item.dataset.index, 10);

    // Remove any previous selected
    const prevSelected = document.querySelector('.pdp-img-subslider .selected');
    if (prevSelected) {
        prevSelected.classList.remove('selected');
    }
    // Add selected
    item.classList.add('selected');

    // Scroll to selected item
    customCarousel.scrollItem(document.querySelector('.pdp-glider'), index);
};

const handleZoom = (e) => {
    // Display overlay
    const container = document.querySelector('.pdp-image-zoom');
    container.classList.remove('d-none');

    // Disable background scroll
    document.querySelector('body').classList.add('no-scroll');

    // Create slider
    const carousel = customCarousel.create(document.querySelector('.zoom-glider'));

    // Set pagination on click
    var clickedIndex = parseInt(e.currentTarget.dataset.index, 10);
    container.querySelector('.js-index').textContent = clickedIndex + 1;

    // Scroll to the correct Item
    carousel.scrollItem(clickedIndex);

    // Set pagination on change event
    document.querySelector('.zoom-glider').addEventListener('glider-slide-visible', function (e) {
        container.querySelector('.js-index').textContent = e.detail.slide + 1;
    });
};

const handleCloseZoom = () => {
    // Hide overlay
    const container = document.querySelector('.pdp-image-zoom');
    container.classList.add('d-none');

    // Re-enable scroll
    document.querySelector('body').classList.remove('no-scroll');
};

const init = () => {
    $(document).on('click', '.pdp-img-subslider .subslider-item', handleClickTile);
    $(document).on('click', '.pdp-glider .mainslider-item', handleZoom);
    $(document).on('click', '.pdp-image-zoom .js-close-zoom', handleCloseZoom);
};

module.exports = {
    init: init
};
