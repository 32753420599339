'use strict';

const processInclude = require('base/util');
// const quickViewInclude = require('base/product/quickView');

$(document).ready(function () {
    processInclude(require('./product/detail'));
    processInclude(require('./product/wishlist'));
    processInclude(require('./product/pralines'));
    require('./product/imageSlider').init();
    require('./product/stockNotification').init();
    require('./product/personalisation').init();
    // require('./product/quickView').onQuickviewReady();
    // quickViewInclude.showQuickview();
    // quickViewInclude.hideDialog();
});
