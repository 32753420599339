'use strict';
var base = require('base/product/detail');
var customBase = require('./base');
const customCarousel = require('../customCarousel');
const Wishlist = require('./wishlist');
const EinsteinSlider = require('./einsteinSlider');

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;
    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }
    if (!pid) {
        pid = $el.data('pid'); // backup incase pid wasnt found before
    }
    return pid;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves url to use when adding a product to the cart. This request will fetch the popup data
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartModalUrl() {
    return $('.add-to-cart-modal-url').val();
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $($el).closest('.product-detail').find('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 *
 */
function handleCartModel() {
    $('body').on('click', '.js-close-addToCartOverlay', function () {
        // Re-enable scroll
        $('body').removeClass('no-scroll');
        // remove the overlay
        $('.AddToCart-overlay-container').remove();
    });
}

/**
 * gets recommendation sliders and initializes them
*/
function handleEinsteinCarousel() {
    // initiate recommendation slider
    var recomGlider = document.querySelector('.recommendation-glider');
    if (recomGlider) {
        customCarousel.create(recomGlider);
        // initiate product wishlist icons
        Wishlist.initProducts();
    }
    // listen to einstein slider mutation
    EinsteinSlider.initMutationObserver('.product-detail');
}

module.exports = base;
module.exports.einsteinCarousel = handleEinsteinCarousel;
module.exports.availability = customBase.availability;
module.exports.showAddToCartModal = function () {
    $('body').on('product:afterAddToCart', function () {
        var addToCartModalUrl = getAddToCartModalUrl();

        // Fetch productData
        $.ajax({
            url: addToCartModalUrl, // Cart-AddProductModal
            type: 'get',
            success: function (data) {
                // Prevent background scroll
                document.querySelector('body').classList.add('no-scroll');

                $('.product-detail').append('<div class="AddToCart-overlay-container"></div>');
                $('.AddToCart-overlay-container').append(data);

                // New event listeners inside the overlay
                handleCartModel();

                handleEinsteinCarousel();

                // Stop spinner
                $.spinner().stop();
            },
            error: function () {
                // possible notification item has been added to the basket
                $.spinner().stop();
            }
        });
    });
};
// this logic has been added to the quickview button (to disable this remove add-to-cart class from the quickview button)
module.exports.addToCart = function () {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }
        var quantity;
        if ($(this).attr('class').indexOf('plp') !== -1) {
            quantity = 1;
            $(this).attr('class').split(' ').forEach(el => {
                if (el.indexOf('pid-') !== -1) {
                    pid = el.split('-')[1];
                }
            });
        } else {
            quantity = getQuantitySelected($(this));
            pid = getPidValue($(this));
        }

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: quantity
        };

        var isRecommendedProduct = $(this).hasClass('quickview');
        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }
        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    $('.minicart').trigger('count:update', data);
                    if (!data.error && !isRecommendedProduct) {
                        $('body').trigger('product:afterAddToCart', data);
                    } else {
                        $.spinner().stop();
                        // Show message when added / or couldn't be added to the cart
                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                            );
                        }
                        // $('.add-to-cart-messages').append(
                        //     '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">'
                        //     + data.message
                        //     + '</div>'
                        // );
                        // Remove message after certain amount of time
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 5000);
                    }

                    miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};
