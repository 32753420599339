'use strict';

const showConfirmationPopup = () => {
    document.querySelector('.stocknotification-confirm').classList.remove('d-none');
};

const handleCloseConfirmationPopup = () => {
    document.querySelector('.stocknotification-confirm').classList.add('d-none');
};

const handleCloseNotificationForm = () => {
    document.querySelector('.stocknotification').classList.add('d-none');
    // Re-enable scroll
    document.querySelector('body').classList.remove('no-scroll');
};

const handleShowNotificationForm = () => {
    document.querySelector('.stocknotification').classList.remove('d-none');
    // Disable background scroll
    document.querySelector('body').classList.add('no-scroll');
};

const handleFormSubmission = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const url = form.action;

    $('.stocknotification').spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $(form).serialize(),
        success: function () {
            handleCloseNotificationForm();
            $('.stocknotification').spinner().stop();
            showConfirmationPopup();
        }
    });

    return false;
};

const init = () => {
    const button = document.querySelector('.js-notify-button');

    if (button) {
        button.addEventListener('click', handleShowNotificationForm);
    }

    const closeBtn = document.querySelector('.js-close-stockNotification');

    if (closeBtn) {
        closeBtn.addEventListener('click', handleCloseNotificationForm);
    }

    const closeConfirmBtn = document.querySelector('.js-close-stockNotification-confirm');

    if (closeConfirmBtn) {
        closeConfirmBtn.addEventListener('click', handleCloseConfirmationPopup);
    }

    const notificationFrm = document.querySelector('.js-stockNotificationForm');

    if (notificationFrm) {
        notificationFrm.addEventListener('submit', handleFormSubmission);
    }
};

module.exports = {
    init: init
};
