'use strict';
const customCarousel = require('../customCarousel');
let slicedPralineGlider;

/**
 * Render and inject matched praline products - initiate carousel if necessary
 * @param {Object} productData - matching praline products - rendered html
 */
function pralineMatchRender(productData) {
    $('.praline-match-injection').html(productData);

    $('.cross-section-carousel-prl').removeClass('d-none');
    $('.js-otherboxes-title').removeClass('d-none');

    customCarousel.create(document.querySelector('.js-praline-matcher'));
}

$('body').on('click', '.js-praline-item', function (e) {
    e.preventDefault();
    $.spinner().start();

    $('.selected').removeClass('selected');
    $(this).addClass('selected');

    var url = $(this).data('url');

    $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            // Show detail
            $('.item-detail').removeClass('d-none');
            $('.js-praline-title').text(data.name);
            $('.js-praline-desc').html(data.description);

            if (data.pralineProductData && data.pralineProductAmount > 1) {
                pralineMatchRender(data.pralineProductData);
            } else {
                $('.praline-match-injection').html('');
                $('.cross-section-carousel-prl, .js-otherboxes-title').addClass('d-none');
            }

            if (data.url) {
                $('.js-praline-url').attr('href', data.url).show();
            } else {
                $('.js-praline-url').hide();
            }

            // Show image detail
            var $carouselWrapper = $('.cross-section-carousel');
            var crossSectionImages = data.image['cross section'];

            $carouselWrapper.toggleClass('d-none', crossSectionImages.length === 0);

            if (crossSectionImages.length > 0) {
                if (slicedPralineGlider) {
                    slicedPralineGlider.destroy();
                    slicedPralineGlider = null;
                }

                const $glider = $('.cross-section-glider', $carouselWrapper);

                $glider.html('').removeClass('glider');
                $glider.siblings('.cross-section-dots').html('');

                // Add all images
                crossSectionImages.forEach(function (image) {
                    $glider.append(`<div data-index="${image.index}"><img src="${image.url}" alt="${image.alt}" class="d-block img-fluid cross-img"/></div>`);
                });

                const $prevArrow = $('.js-cross-section-prev', $carouselWrapper);
                const $nextArrow = $('.js-cross-section-next', $carouselWrapper);

                $prevArrow.add($nextArrow).toggleClass('d-none', crossSectionImages.length === 1);

                // Only create carousel if there are multiple images
                if (crossSectionImages.length > 1) {
                    // Create new arrows
                    $prevArrow.after($prevArrow.clone()).remove();
                    $nextArrow.after($nextArrow.clone()).remove();

                    // check if all images are loaded before initializing the carousel.
                    let imgs = $glider[0].querySelectorAll('.cross-img');
                    let counter = 0;

                    [].forEach.call(imgs, function (img) {
                        if (img.complete) {
                            counter++;
                            if (counter === imgs.length) {
                                slicedPralineGlider = customCarousel.create($glider[0]);
                            }
                        } else {
                            img.addEventListener('load', function () {
                                counter++;
                                if (counter === imgs.length) {
                                    slicedPralineGlider = customCarousel.create($glider[0]);
                                }
                            }, false);
                        }
                    });
                }
            }

            // scroll the box-content into view
            $('html, body').animate({
                scrollTop: $('.box-content').offset().top
            });

            if (slicedPralineGlider) {
                slicedPralineGlider.refresh(true);
            }

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
});

// close the detail
$('body').on('click', '.js-close-praline', function () {
    $('.item-detail').addClass('d-none');
});
