'use strict';

const handlePersonaliseBtn = (e) => {
    $('.js-active-personalisation, .js-inactive-personalisation').toggleClass('d-none');
    $('.pdp-main-image').toggleClass('h-100');
    $('.personalisation-txt-overlay').toggleClass('d-none');
    $('.product-info-personalisation').toggleClass('d-none');
    $('.js-availabilty-container').toggleClass(['border-right', 'mr-4', 'pr-4']);
    $('.pdp-img-subslider').toggleClass('d-md-block');

    const slide = $(e.currentTarget).hasClass('js-activate-personalisation') ? $('.pdp-img-subslider .subslider-item').last() : $('.pdp-img-subslider .subslider-item').first();
    slide.trigger('click');

    var $input = $('.js-personal-text');
    $input.focus();
    $input.val('');
};

const init = () => {
    $('.js-deactivate-personalisation, .js-activate-personalisation').on('click', handlePersonaliseBtn);
    $('.js-personal-text').on('input', function (e) {
        var $mainImg = $('.pdp-main-image .mainslider-item').last();
        var $zoomImg = $('.zoom-glider .image-wrapper').last();

        const overlay = $mainImg
            .add($zoomImg)
            .find('.personalisation-txt-overlay');

        const text = e.target.value || overlay.attr('data-placeholer');
        overlay.text(text);
    });

    if ($('.product-detail').data('openpersonalisation')) {
        $('.js-activate-personalisation').trigger('click');
    }
};

module.exports = {
    init: init
};
